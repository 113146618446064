<template>
  <div class="layout__header">
    <div class="header__left">
      <breadcrumb />
    </div>
    <div class="header__right">
      <a-dropdown :trigger="['click']" placement="bottomRight" overlayClassName="nav-dropdown-menu">
        <div class="header__avatar">
          <a-avatar>
            <template #icon>
              <img :src="avatar" />
            </template>
          </a-avatar>
          <caret-down-outlined :style="{ fontSize: '12px', marginLeft: '16px', color: '#999' }" />
        </div>
        <template #overlay>
          <a-menu>
            <a-menu-item @click="logout()">
              <i class="ui-icon ui-icon-logout"></i>退出登录
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <callCenter v-model:visible="visible" />
      <resetPasswordDialog v-model:visible="resetVisible" />
      <editPasswordDialog v-model:visible="editVisible" />
    </div>
  </div>
</template>
<script lang="ts">
import { CaretDownOutlined } from '@ant-design/icons-vue';
import { MqttService } from '@yunxi/service';
import { message } from 'ant-design-vue';
import md5 from 'md5';
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { mapState, useStore } from 'vuex';

import { MqttItem } from '@/api/interface';
import { getWebMessage, readWebMessage, switchRootOrg } from '@/api/user';
import { WebMessageItem } from '@/api/user/interface';
import editPasswordDialog from '@/components/dialog/edit-password-dialog.vue';
import resetPasswordDialog from '@/components/dialog/reset-password-dialog.vue';
import { MessageType, messageTypeMap } from '@/constant/user';
import { formatDate } from '@/utils';

import breadcrumb from '../breadcrumb/index.vue';
import callCenter from '../call-center/index.vue';
export default defineComponent({
  name: 'layoutHeader',
  components: {
    callCenter,
    breadcrumb,
    CaretDownOutlined,
    resetPasswordDialog,
    editPasswordDialog,
  },
  computed: {
    ...mapState({
      accountId: (state: any) => state.user.accountId,
      name: (state: any) => state.user.name,
      avatar: (state: any) => state.user.avatar,
      orgInfo: (state: any) => state.user.orgInfo,
      rootOrgList: (state: any) => state.user.rootOrgList,
      orgId: (state: any) => state.user.orgId,
    }),
  },
  setup() {
    const Mqtt = new MqttService({
      host: import.meta.env.VITE_APP_MQ_API,
      port: 8084,
      connected: true, //是否自动连接
      options: {
        keepalive: 30,
        clean: true,
        connectTimeout: 4000,
      },
    });
    const visible = ref(false);
    const resetVisible = ref(false);
    const editVisible = ref(false);

    const store = useStore();
    const router = useRouter();
    const messageList = ref<WebMessageItem[]>([]);
    const resetPasssword = () => {
      if (store.state.user.mobile) {
        resetVisible.value = true;
        return;
      }
      editVisible.value = true;
    };
    const changeApplication = () => {
      location.href = import.meta.env.VITE_APP_SAAS_HOST;
    };
    const buyService = () => {
      location.href =
        import.meta.env.VITE_APP_SAAS_HOST +
        `#/application/business-setting/service-purchase?type=partner`;
    };
    // 退出登录
    const logout = () => {
      store.dispatch('user/logout').then(() => {
        router.push('/login');
      });
    };

    async function getWebMessageEvent() {
      const { data } = await getWebMessage();
      messageList.value = data;
    }
    // <<< mqtt

    function mqtt() {
      Mqtt.subscribe(`org/${md5(store.state.user.orgId)}`, res => {
        const { type } = res as MqttItem;
        if (type === 'newMessage') {
          getWebMessageEvent();
        }
      });
    }
    // mqtt >>>
    /** 弹框扩容 */
    function buyStorage() {
      window.open(
        `${
          import.meta.env.VITE_APP_SAAS_HOST
        }/#/application/business-setting/resources-service?active=true&type=partner`,
        '__blank',
      );
    }

    function setCenter() {
      window.open(
        `${import.meta.env.VITE_APP_SAAS_HOST}/#/application/business-setting?type=partner`,
        '__blank',
      );
    }

    async function readMessage(msgId: string, type: number) {
      if (type === MessageType.apply) {
        window.open(
          `${
            import.meta.env.VITE_APP_SAAS_HOST
          }/#/application/business-setting/setting-organization/setting-member?type=partner`,
          '_blank',
        );
        return;
      }
      await readWebMessage({ msgId });
      getWebMessageEvent();
    }
    const orgId = store.state.user.orgId;
    const switchBusiness = async (id: string) => {
      if (id === orgId) {
        return;
      }
      const res = await switchRootOrg({
        rootOrgId: id,
      });
      if (res.data.ok) {
        message.success('操作成功');
      }
      location.reload();
    };
    onMounted(() => {
      getWebMessageEvent();
      mqtt();
    });
    onUnmounted(() => {
      Mqtt.unsubscribe(`org/${md5(store.state.user.orgId)}`);
    });
    return {
      setCenter,
      messageList,
      formatDate,
      readMessage,
      buyStorage,
      logout,
      visible,
      resetVisible,
      MessageType,
      messageTypeMap,
      changeApplication,
      editVisible,
      resetPasssword,
      switchBusiness,
      buyService,
    };
  },
});
</script>
<style lang="scss">
.nav-header-mp-container {
  .title {
    padding-bottom: 8px;
    margin-top: 16px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }

  .desc {
    font-size: 12px;
  }

  .content {
    flex: 1;
    height: 100%;
    margin-left: 26px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.4);
  }

  .tag-bar {
    width: 92px;
    height: 18px;
    margin-bottom: 10px;
    font-size: 12px;
    color: #1374f6;
    background: url('../../../assets/images/nav/tag-bar.svg') center no-repeat;
    background-size: cover;

    p {
      padding-top: 1px;
      white-space: nowrap;
      transform: scale(0.7) translateX(-4px);
    }
  }

  .source {
    width: 284px;
    height: 124px;
    margin-bottom: 12px;
    background: url('../../../assets/images/nav/sczx.svg') center no-repeat;

    @include flex-space-between;
  }

  .pre {
    width: 284px;
    height: 124px;
    margin-bottom: 12px;
    background: url('../../../assets/images/nav/zbyg.svg') center no-repeat;
    @include flex-space-between;
  }

  .analyze {
    width: 284px;
    height: 124px;
    background: url('../../../assets/images/nav/fxs.svg') center no-repeat;
    @include flex-space-between;
  }

  .code {
    width: 100px;
    margin-right: 16px;
    border-radius: 6px;
  }
}

.nav-dropdown-menu.ant-dropdown {
  padding: 0;
  border-radius: 4px;

  .ant-dropdown-menu {
    padding: 0 0 4px;
  }

  .divided {
    height: 1px;
    background: rgba(0, 0, 0, 0.06);
  }

  .menu-info {
    position: relative;
    width: 276px;
    height: 116px;
    overflow: hidden;
    background: url('@/assets/images/nav/zhuanye.svg') center center no-repeat;
    background-size: cover;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    .menu-info-box-switch {
      position: absolute;
      right: 40px;
      bottom: 15px;
      width: 28px;
      height: 28px;
      cursor: pointer;
      // background: rgba(255, 255, 255, 0.08);
    }

    .menu-info-box {
      margin: 39px 0 0 38px;

      p {
        &:nth-of-type(1) {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          color: #f1dabe;
        }

        &:nth-of-type(2) {
          display: flex;
          align-items: center;
          width: 180px;
          margin: 2px 0 4px 0;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          color: #d7be95;

          span {
            &:first-of-type {
              max-width: 144px;
              margin-right: 4px;
            }

            &:nth-of-type(2) {
              display: flex;
              flex-shrink: 0;
              align-items: center;
              justify-content: center;
              width: 42px;
              height: 20px;
              font-size: 12px;
              color: rgba(255, 255, 255, 0.6);
              background: linear-gradient(135deg, #989a9d 0%, #404245 100%);
              border-radius: 4px;
            }
          }
        }

        &:nth-of-type(3) {
          font-size: 12px;
          line-height: 18px;
          color: rgba(255, 255, 255, 0.4);
        }
      }
    }
  }

  .ant-dropdown-menu-item {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 18px;
    margin: 10px 0;
    overflow: hidden;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);

    &:last-of-type {
      margin: 4px 0 0 0;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }

    i {
      margin-right: 10px;
    }

    .ant-dropdown-menu-title-content {
      display: flex;
      align-items: center;
      font-size: 13px;
    }
  }
}

.layout__header {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  font-size: 20px;

  & .header__left {
    display: flex;
    flex-grow: 1;
    align-items: center;

    & .ant-tabs-bar {
      margin: 0;
      border: none;
    }

    & .group__tabs {
      flex-grow: 1;
      width: 500px;
      margin-right: 22px;
      margin-left: 22px;
    }
  }

  & .header__right {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;

    .notify-center {
      position: relative;
      margin-left: 24px;
      cursor: pointer;

      &:hover .notify-center-box-wrap {
        display: block;
      }

      .notify-center-box-wrap {
        position: absolute;
        top: 62px;
        right: -24px;
        z-index: 10000;
        display: none;
        width: 384px;
        line-height: 24px;
        cursor: auto;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 1px 6px 0 rgba(0, 61, 140, 0.09);

        .title-line {
          padding: 16px 0 10px 23px;
          margin-bottom: 8px;
          font-size: 16px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.8);
          border-bottom: 1px solid rgba(0, 0, 0, 0.06);
        }

        .content-box-wrap {
          height: 376px;
          overflow-y: auto;

          .content-box {
            padding: 8px 24px;
            cursor: pointer;

            &:hover {
              background: #f7f8fa;
            }
          }
        }
      }

      .no-read-circle {
        position: absolute;
        top: 23px;
        right: 0;
        width: 10px;
        height: 10px;
        background: #f62213;
        border-radius: 50%;
      }
    }

    & .header__avatar {
      display: flex;
      align-items: center;
      cursor: pointer;

      & .header__avatar-name {
        margin-left: 6px;
        font-size: 14px;
        vertical-align: middle;
      }
    }
  }
}

.menu-info_dropdown {
  top: 84px !important;
  left: -20px !important;
  padding: unset !important;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 1px 6px 0 rgba(0, 61, 140, 0.09);

  &-title {
    width: 228px;
    height: 44px;
    padding-left: 16px;
    font-size: 16px;
    font-weight: 600;
    line-height: 44px;
    color: rgba(0, 0, 0, 0.8);
  }

  &-content {
    max-height: 220px;
    padding: 0 8px 12px;
    overflow-y: overlay;

    .disabledSwitchItem {
      background: rgba(0, 0, 0, 0.04) !important;
      border-radius: 2px;

      .disabledSelect {
        font-weight: 600 !important;
        cursor: not-allowed !important;
      }
    }

    .ant-dropdown-menu-item {
      height: 44px !important;
      padding: 12px 8px !important;
      margin: unset !important;

      .ant-dropdown-menu-title-content {
        .disabledSelect {
          width: 196px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .ant-dropdown-menu {
    padding: unset !important;
  }

  .ant-dropdown-menu-item:hover {
    background: #f0f6ff !important;
    border-radius: 2px;
  }

  .disabledSwitchItem:hover {
    background: rgba(0, 0, 0, 0.04) !important;
    border-radius: 2px;
  }
}
</style>
