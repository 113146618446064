import { validateHelper } from '@yunxi/helper';
import type { RuleObject } from 'ant-design-vue/es/form';

export const validateCode = async (_rule: RuleObject, value: string) => {
  // if (!value) {
  //   return Promise.reject('验证码不能为空');
  // }
  if ((value && value.length < 4) || (value && value.length > 4)) {
    return Promise.reject('短信验证码是4位数字');
  }
  return Promise.resolve();
};
export const validatePhone = async (_rule: RuleObject, value: string) => {
  if (!validateHelper.validatePhone(value)) {
    return Promise.reject(value ? '请输入正确的手机号码' : '请输入手机号码');
  }
  return Promise.resolve();
};
export const validateEmail = async (_rule: RuleObject, value: string) => {
  if (!validateHelper.validateEmail(value)) {
    return Promise.reject(value ? '请输入正确的邮箱帐号' : '请输入邮箱帐号');
  }
  return Promise.resolve();
};
