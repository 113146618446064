<template>
  <a-config-provider :locale="locale" :autoInsertSpaceInButton="false">
    <router-view />
  </a-config-provider>
</template>

<script>
import { tim } from '@/utils/timService';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { getUserSig } from './api/common';
dayjs.locale('zh-cn');

export default {
  data() {
    return {
      locale: zhCN,
    };
  },
  watch: {
    'this.$store.state.user.userId'(n) {
      if (!n) {
        return;
      }
      this.TIMLogin();
    },
  },
  methods: {
    async TIMLogin() {
      const { data } = await getUserSig();
      tim.login({
        userID: this.$store.state.user.userId,
        userSig: data.userSig,
        userId: this.$store.state.user.userId,
        avatar: this.$store.state.user.avatar,
        username: this.$store.state.user.nickname,
      });
    },
  },
};
</script>
