import { Scene } from '@/constant/oss';
import { IVodOssScene } from '~shared/business-types';
import {
  GetCosPolicySignReply,
  GetGaoDingAuthorizeReply,
  GetOrgSubscribeReply,
  GetVodPolicySignReply,
} from '~typings/api';
import { CommonReply, CreateMaterialReq, GetGaoDingUseCertReply } from '~typings/sass-api';

import { http } from '../http-server';

// 云点播token
export function getPolicyToken(payload: { scene: IVodOssScene }) {
  return http.get<GetVodPolicySignReply>('/vod/get-policy-sign', payload);
}

// 腾讯云获取oss token
export function getPolicyTokenCos(payload: { scene: Scene }) {
  return http.get<GetCosPolicySignReply>('/cos/get-policy-sign', payload);
}

// 稿定 sdk
export function getGaoDingToken() {
  return http.get<GetGaoDingAuthorizeReply>(
    '/gaoding/get-authorize',
    {},
    {
      baseURL: import.meta.env.VITE_APP_SAAS_API,
    },
  );
}

// 用户权益接口
export function getGaoDingCert(payload: { worksId: string; abilityCode: string }) {
  return http.post<GetGaoDingUseCertReply>('/gaoding/get-use-cert', payload, {
    baseURL: import.meta.env.VITE_APP_SAAS_API,
  });
}

// 2，0搞定 保存素材 有特殊逻辑
export function saveGaoDing(data: CreateMaterialReq) {
  return http.post<CommonReply>('/material/create', data, {
    baseURL: import.meta.env.VITE_APP_SAAS_API,
  });
}

// 建议、反馈
export function suggestSubmit(payload: { source: number; content: string }) {
  return http.post<{ ok: boolean }>('/suggestion/create', payload);
}
// 数据上报
export function reportData(data: { body: string }) {
  return http.post('/report/create/data', data, {
    baseURL: import.meta.env.VITE_H5_URL,
  });
}

//获取IM的userSig
export function getUserSig() {
  return http.get<{ userSig: string }>('/IM/get-user-sig', null, {
    baseURL: import.meta.env.VITE_APP_SAAS_API,
  });
}

// 快剪跳转
export function getKuaiJianUrl(data: { type: number }) {
  return http.get('/kuaijian/get-url', data, {
    baseURL: import.meta.env.VITE_APP_SAAS_API,
  });
}

export function getUserPluginsInfo() {
  return http.get<GetOrgSubscribeReply>('/dys/get-org-subscribe');
}
