export enum DisabledStatus {
  Open = 1, // 启用
  Disabled = 2, // 禁用
}

export enum ChildStatus {
  is = 1, // 启用
  no = 0, // 禁用
}

export enum Role {
  anchor = '404009370855149568', //主播
  superAdmin = '399591043319730176',
}

export enum OrgTag {
  shop, // 门店
  role, // 角色
  account, //帐号
}
