import { createRouter, createWebHashHistory } from 'vue-router';

import { hostPartner } from '@/constant/route-name';

// import { dataRouter } from '@/router/modules/data';
import bigScreenRouter from './modules/big-screen';
// 相机
import cameraRouter from './modules/camera';
import deviceRouter from './modules/device';
// 组织
import organizationRouter from './modules/organization';
// 伴侣
import partnerRouter from './modules/partner';
import pluginsRouter from './modules/plugins';
// 素材
import sourceRouter from './modules/source';
import { permission } from './permission';

// 实例化路由
const router = createRouter({
  history: createWebHashHistory('/'),
  routes: [
    { path: '/', redirect: { name: hostPartner } },
    {
      path: '/login',
      component: () => import('@/views/login/index.vue'),
      children: [
        {
          path: '',
          name: 'login',
          component: () => import('@/views/login/login.vue'),
        },
        {
          path: 'register',
          name: 'register',
          component: () => import('@/views/login/register.vue'),
        },
        {
          path: 'reset',
          name: 'reset',
          component: () => import('@/views/login/reset.vue'),
        },
        {
          path: 'qrcode',
          name: 'qrcode',
          component: () => import('@/views/login/qrcode.vue'),
        },
      ],
    },
    {
      path: '/403',
      name: '403',
      component: () => import('@/errorPages/403.vue'),
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/errorPages/404.vue'),
    },
    {
      path: '/500',
      name: '500',
      component: () => import('@/errorPages/500.vue'),
    },
    {
      path: '/big-screen-full-pic',
      name: 'big-screen-full-pic',
      component: () => import('@/views/big-screen/picture/full-picture.vue'),
    },
    {
      path: '/big-screen-data-total',
      name: 'big-screen-data-total',
      component: () => import('@/views/big-screen/data/total.vue'),
    },
    {
      path: '/big-screen-data-realtime',
      name: 'big-screen-data-realtime',
      component: () => import('@/views/big-screen/data/realtime.vue'),
    },
    {
      path: '/big-screen-data-realtime',
      name: 'big-screen-data-realtime',
      component: () => import('@/views/big-screen/data/realtime.vue'),
    },
    {
      path: '/remote-screen',
      name: 'remote-screen',
      component: () => import('@/views/room/index'),
    },
    // dataRouter,
    partnerRouter,
    sourceRouter,
    organizationRouter,
    deviceRouter,
    bigScreenRouter,
    pluginsRouter,
    ...cameraRouter,
  ],
});

permission(router);

export default router;
