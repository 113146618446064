<template>
  <a-layout class="layout">
    <!-- 侧边 -->
    <a-layout-sider v-model:width="width">
      <layout-menu v-model:width="width" />
    </a-layout-sider>

    <a-layout class="layout-main">
      <!-- 头部 -->
      <a-layout-header class="header">
        <layout-header v-if="showSettings" />
        <cameraHeader v-else />
      </a-layout-header>

      <!-- 内容 -->
      <a-layout-content :class="[isGaryBackground ? 'gray-container' : 'container']">
        <router-view></router-view>
        <router-view name="child"></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { cameraPath, sourceLibrary } from '@/constant/route-name';

import cameraHeader from './header/camera-header.vue';
import layoutHeader from './header/index.vue';
import layoutMenu from './menu/index.vue';
const width = ref(108);
const route = useRoute();

const isGaryBackground = ref(false);

const showSettings = computed(() => {
  return !cameraPath.includes(location.hash);
});

watch(
  () => route.name,
  routeName => {
    isGaryBackground.value = [sourceLibrary].includes(routeName as string);
  },
  { immediate: true },
);
</script>
<style lang="scss" scoped>
.layout {
  min-width: 1200px;
  min-height: 100vh;

  .layout-main {
    background: #fff;
  }

  :deep(.ant-layout-sider) {
    background: #1374f6;
    transition: none;
  }

  & .header {
    padding: 0;
    background-color: #fff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  }

  & .container {
    background-color: #fff;
  }

  & .gray-container {
    background: #f2f3f5;
  }

  & .container::-webkit-scrollbar {
    width: 6px;
  }

  & .container::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 10px;
    box-shadow: inset 0 0 5px #d8d8d8;
  }

  & .container::-webkit-scrollbar-track {
    background: #ededed;
    box-shadow: inset 0 0 5px #d8d8d8;
  }
}
</style>
