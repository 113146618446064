import { http } from '../http-server';
import { ICreateScript, IUpdateScript, IListScript } from './interface';
import { LocationQueryValue } from 'vue-router';
// 发起ppt/pdf转png的任务
export function createDoc(data: { url: string; name: string }) {
  return http.post('script/create-doc', data);
}

// 根据jobId获取转码结果
export function getDoc(jobId: string) {
  return http.get('/script/get-doc/' + jobId);
}

// 创建自定义脚本
export function createScript(data: ICreateScript) {
  return http.post('/script/create', data);
}

// 脚本列表
export function getScriptList(data: IListScript) {
  return http.get('/script/list', data);
}

// 获取脚本详情
export function getScriptInfo(scriptId: string) {
  return http.get('/script/get-info/' + scriptId);
}

// 更新脚本
export function updateScript(data: IUpdateScript) {
  return http.post('/script/update', data);
}
// 删除脚本
export function deleteScript(data: { scriptId: string[] }) {
  return http.post('/script/delete', data);
}

// 获取脚本类型列表
export function getTypeList() {
  return http.get('/script/list-type');
}
// 敏感词检测
export function checkDict(data: any) {
  return http.post('/script/check-dict', data);
}
// 【后台】检查脚本是否被使用接口
export function checkScriptUse(data: { orgId?: string; scriptIds: LocationQueryValue[] }) {
  return http.post('/script/check-use', data);
}
