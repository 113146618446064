import { cameraAbout, cameraDebug } from '@/constant/route-name';
import Home from '@/views/home/index.vue';

const router = [
  {
    path: '/camera-setting',
    component: Home,
    redirect: { name: cameraDebug },
    children: [
      {
        name: cameraDebug,
        path: '',
        component: () => import('@/views/camera/debug/index.vue'),
      },
    ],
  },
  {
    path: '/camera-about',
    component: Home,
    redirect: { name: cameraAbout },
    children: [
      {
        name: cameraAbout,
        path: '',
        component: () => import('@/views/camera/about/index.vue'),
      },
    ],
  },
];

export default router;
