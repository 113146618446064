import { BuyResourceType } from '@/constant/resource';

import { http } from '../http-server';

// 获取用户流量/存储信息
export function getUserStoreInfo(payload: { accountType: BuyResourceType }) {
  return http.get('/resource/get-account', payload);
}

// 获取流量/存储剩余
export function getAllResourceRemain() {
  return http.get('/resource/overview-account');
}
