<template>
  <a-breadcrumb class="breadcrumb">
    <a-breadcrumb-item
      v-for="(item, idx) in breadcrumbList"
      :key="item.name"
      :id="`breadName${idx}`"
    >
      <a @click.prevent="handleLink(item.name)">{{ item.showName || item.name }}</a>
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { computed, defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const router = useRouter();
    const route = useRoute();

    const breadcrumbList: any = computed(() => {
      const list = [
        {
          name: route.name,
          showName: route.meta.name,
        },
      ];

      const parent = route.meta.parentRouteName;
      if (parent) {
        list.unshift({
          name: parent,
        });
      }
      return list;
    });

    const handleLink = (name: string) => {
      router.push({ name });
    };

    return { handleLink, breadcrumbList };
  },
});
</script>

<style lang="scss" scoped>
.breadcrumb {
  height: 56px;
  line-height: 56px;
}
</style>
