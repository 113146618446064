import dayjs from 'dayjs';
import { RouteLocationNormalizedLoaded, Router } from 'vue-router';

import { reportData } from '@/api/common';
import { getKuaiJianUrl } from '@/api/common';
// 后端给过来时间戳都是秒级别，dayjs需要毫秒级别 函数自动处理
import { hostPartner, onlineDesign } from '@/constant/route-name';
import store from '@/store';
export const formatDate = (val: number, type: string) => {
  return dayjs(val * 1000).format(type);
};

export function reportDataWithUser(
  outParams: Record<string, unknown>,
  reportScene = 'partnerScriptOperate',
) {
  reportData({
    body: JSON.stringify({
      reportScene,
      params: {
        rootOrgId: store.state.user.orgId,
        userId: store.state.user.userId,
        ...outParams,
      },
    }),
  }).then(() => {
    //
  });
}

/**
 * 登陆/注册 重定向逻辑/业务逻辑判断，默认跳sass2.0 产品页面
 * @param router
 * @param route
 */
export function redirectPath(router: Router, route: RouteLocationNormalizedLoaded) {
  if (route.query?.onlineDesign === 'onlineDesign') {
    return router.push({ name: onlineDesign });
  }

  if (import.meta.env.VITE_ENV === 'development') return router.push({ name: hostPartner });

  window.location.href = import.meta.env.VITE_APP_SAAS_HOST + '?from=partner';
}

export const toKj = async () => {
  if (store.state.user.kjIsActive) {
    const { data } = await getKuaiJianUrl({ type: 0 });
    location.href = data.url;
    return;
  }
  location.href =
    import.meta.env.VITE_APP_SAAS_HOST +
    '#/application/business-setting/service-distribute?serviceType=kj';
};
