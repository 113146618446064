import {
  defineScriptCreate,
  defineScriptEdit,
  directorScript,
  onlineDesign,
  pptScriptCreate,
  pptScriptEdit,
  sourceLibrary,
} from '@/constant/route-name';
import Home from '@/views/home/index.vue';

const sourceRouter = {
  path: '/source',
  component: Home,
  redirect: { name: directorScript },
  children: [
    /*{
      name: liveDecoration,
      path: 'live-decoration',
      component: () => import('@/views/source/live-decoration/index.vue'),
    },*/
    /* {
      name: liveDecorationCreate,
      path: 'live-decoration-template-add',
      meta: {
        name: '创建模版',
        parentRouteName: liveDecoration,
      },
      component: () => import('@/views/source/live-decoration/add/add.vue'),
    },
    {
      name: liveDecorationEdit,
      path: 'live-decoration-template-edit',
      meta: {
        name: '新建模版',
        parentRouteName: liveDecoration,
      },
      component: () => import('@/views/source/live-decoration/temp/index.vue'),
    },
    {
      name: liveDecorationSave,
      path: 'live-decoration-template-save',
      meta: {
        name: '保存模版',
        parentRouteName: liveDecoration,
      },
      component: () => import('@/views/source/live-decoration/success/index.vue'),
    },*/

    {
      name: directorScript,
      path: 'director-script',
      component: () => import('@/views/source/director-script/index.vue'),
    },
    {
      name: onlineDesign,
      path: 'online-design',
      component: () => import('@/views/source/online-design/index.vue'),
    },
    {
      name: sourceLibrary,
      path: 'library',
      component: () => import('@/views/source/library/index.vue'),
    },
    {
      name: pptScriptCreate,
      path: 'script-ppt',
      meta: {
        name: '创建脚本',
        parentRouteName: directorScript,
      },
      component: () => import('@/views/source/director-script/script-ppt.vue'),
    },
    {
      name: defineScriptCreate,
      path: 'script-define',
      component: () => import('@/views/source/director-script/script-define.vue'),
      meta: {
        name: '创建脚本',
        parentRouteName: directorScript,
      },
    },
    {
      name: pptScriptEdit,
      path: 'script-ppt-update',
      meta: {
        name: '编辑脚本',
        parentRouteName: directorScript,
      },
      component: () => import('@/views/source/director-script/script-ppt.vue'),
    },
    {
      name: defineScriptEdit,
      path: 'script-define-update',
      component: () => import('@/views/source/director-script/script-define.vue'),
      meta: {
        name: '编辑脚本',
        parentRouteName: directorScript,
      },
    },
  ],
};

export default sourceRouter;
