import 'ant-design-vue/dist/antd.less';
import '@/style/index.css';
import '@/style/auto.css';
import '@/style/antd.scss';
import '@yunxi/ant-business-ui/lib/style.css';

import YxBusiness from '@yunxi/ant-business-ui';
import { MqttService } from '@yunxi/service';
import Antd from 'ant-design-vue';
import { createApp } from 'vue';
import infiniteScroll from 'vue3-infinite-scroll-better';

import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
const app = createApp(App);

app.use(infiniteScroll);
app.use(store);
app.use(router);
app.use(Antd);
app.use(YxBusiness);
app.directive('preventReClick', {
  mounted(el, binding) {
    el.addEventListener('click', () => {
      if (!el.disabled) {
        el.disabled = true;
        setTimeout(() => {
          el.disabled = false;
        }, binding.value || 3000);
      }
    });
  },
});
app.config.globalProperties.$mqtt = new MqttService({
  host: import.meta.env.VITE_APP_MQ_API,
  port: 8084,
  connected: true, //是否自动连接
  options: {
    keepalive: 30,
    clean: true,
    connectTimeout: 4000,
  },
});

app.mount('#app');
