import Home from '@/views/home/index.vue';
import {
  organizationShop,
  organizationShopCreate,
  organizationShopEdit,
  organizationStaffCreate,
  organizationStaffEdit,
  organizationStaffJoin,
} from '@/constant/route-name';

const organizationRouter = {
  path: '/organization',
  component: Home,
  redirect: { name: organizationShop },
  children: [
    {
      name: organizationShop,
      path: 'store',
      component: () => import('@/views/organization/index.vue'),
    },
    {
      name: organizationShopCreate,
      path: 'store-add',
      meta: {
        name: '添加门店',
        parentRouteName: organizationShop,
      },
      component: () => import('@/views/organization/update-store.vue'),
    },
    {
      name: organizationStaffCreate,
      path: 'staff-add',
      meta: {
        name: '添加帐号',
        parentRouteName: organizationShop,
      },
      component: () => import('@/views/organization/update-staff.vue'),
    },
    {
      name: organizationShopEdit,
      path: 'store-edit',
      meta: {
        name: '编辑门店',
        parentRouteName: organizationShop,
      },
      component: () => import('@/views/organization/update-store.vue'),
    },
    {
      name: organizationStaffEdit,
      path: 'staff-edit',
      meta: {
        name: '编辑帐号',
        parentRouteName: organizationShop,
      },
      component: () => import('@/views/organization/update-staff.vue'),
    },
    {
      name: organizationStaffJoin,
      path: 'staff-join',
      meta: {
        name: '审批加入',
        parentRouteName: organizationShop,
      },
      component: () => import('@/views/organization/update-staff.vue'),
    },
  ],
};

export default organizationRouter;
