import { http, rawHttp } from '../http-server';
import {
  IBindMobile,
  LoginType,
  registerType,
  updatePasswordType,
  userSendSmsType,
  WebMessageItem,
} from './interface';

/** 发送验证码 */
export function userSendSms(payload: userSendSmsType) {
  return rawHttp.post('/user/send-sms', payload);
}

/** 登陆 */
export function login(payload: LoginType) {
  return rawHttp.post('/user/login', payload);
}

/** 获取个人信息 */
export function getInfo(payload: { userId?: string }) {
  return http.get('/user/get', payload);
}

/** 更新密码 */
export function updatePassword(payload: updatePasswordType) {
  return rawHttp.post('/user/update-password', payload);
}

/** 修改密码 */
export function changePassword(payload: { oldPassword: string; newPassword: string }) {
  return rawHttp.post('/user/change-password', payload);
}

/** 注册 */
export function register(payload: registerType) {
  return rawHttp.post('/user/register', payload);
}

/** 获取站内信 */
export function getWebMessage() {
  return http.get<WebMessageItem[]>('/message/list');
}

/** 阅读站内信 */
export function readWebMessage(payLoad: { msgId: string }) {
  return http.get<{ ok: boolean }>('/message/read', payLoad);
}

/** 获取注册/登录 二维码 */
export function getSceneQrcode() {
  return http.get<{ expireTime: number; url: string; scene: string }>(
    '/user/scene-qrcode',
    {},
    { headers: { 'Cache-Control': 'no-cache' } },
  );
}

/** 获取注册/登录/绑定微信 扫码状态 */
export function getSceneQrcodeResult(payLoad: { scene: string }) {
  return rawHttp.get('/user/scan-qrcode-result', payLoad);
}

/** 绑定手机号 */
export function bindMobile(payload: IBindMobile) {
  return rawHttp.post('/user/account-bind-mobile', payload);
}
/** 切换企业 */
export function switchRootOrg(payload: { rootOrgId: string }) {
  return rawHttp.post('/user/switch-user-root-org', payload);
}
