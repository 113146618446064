import { IMaterialGroupItem, IVideoItem, MaterialType } from '~shared/business-types';
import {
  CommonReply,
  CreateMaterialFileReq,
  ListMaterialGroupFileRelationReply,
} from '~typings/api';

import { http } from '../http-server';

// 素材分组-创建接口
interface ISourceMaterial {
  materialType: number | undefined;
  name: string;
  groupId?: string;
}
export function materialCreate(data: ISourceMaterial) {
  return http.post('/material/group/create', data);
}
// 素材分组-更新接口
export function materialUpdate(data: ISourceMaterial) {
  return http.post('/material/group/update/', data);
}
// 素材分组-列表接口
export function materialList(data: { page: number; pageSize: number; materialType: MaterialType }) {
  return http.get<IMaterialGroupItem[]>('/material/group/list', data);
}
// 素材分组-删除接口
export function materialDel(data: { materialType: number; groupId: number }) {
  return http.post('/material/group/delete', data);
}
// 素材分组中-素材数据列表接口
export function relationList(data: unknown) {
  return http.get('/material/group-relation/list', data);
}

// 素材中心-数据列表接口
export function centerList(data: unknown) {
  return http.get('/material/group-center/list', data);
}
// 素材添加至分组接口
export function relationAdd(data: { materialType: number; name?: string; groupId?: string }) {
  return http.post('/material/group/create-relation', data);
}
// 素材分组中-素材移除接口
export function relationRemove(data: {
  groupId: string;
  relationIds: string[];
  materialType: MaterialType;
}) {
  return http.post('/material/group/delete-relation', data);
}

// 素材分组中-素材发布\取消发布接口
export function relationOperate(data: { groupId: string; relationIds: []; status: number }) {
  return http.post('/material/group/update-relation', data);
}

// 素材分组中-素材移动接口
export function relationMove(data: unknown) {
  return http.post('/material/group-relation/move', data);
}

// 素材中心-素材删除接口
export function centerDel(data: unknown) {
  return http.post('/material/group-center/delete', data);
}

// 素材中心修改素材信息接口
export function updateInfo(data: {
  materialType: number;
  materialId: string;
  name: string;
  imageId?: string;
  fileId?: string;
}) {
  return http.post('/material/update', data);
}

// 素材中心-保存上传素材接口
export function saveUploadInfo(data: {
  fileId: string;
  groupId: string;
  materialPath: string;
  materialType: MaterialType;
}) {
  return http.post('/material/create', data);
}

// 素材中心-图片列表接口
export function imageGroupList(data: {
  groupId: string;
  status: number;
  page: number;
  pageSize: number;
}) {
  return http.get('/material/group/list-image-relation', data);
}
// 素材中心-视频列表接口
export function videoGroupList(data: {
  groupId: string;
  transcodeStatus?: number;
  page: number;
  pageSize: number;
  materialType: number;
}) {
  return http.get<IVideoItem[]>('/material/group/list-video-relation', data);
}

// 根据fileId文件获取素材信息接口
export function getSourceByFileId(data: { materialType: MaterialType; fileId: string }) {
  return http.get('/material/get-by-fileid', data);
}

// 素材库转码任务进度接口
export function getTranscodeProcess(data: {
  fileId: string;
  materialId: string;
  materialType: MaterialType;
  taskType: 'video_transcode'; // 视频转码
}) {
  return http.get<{
    progress: number;
    transcodeStatus: number;
    url: string;
    vodStatus: 'PROCESSING' | 'SUCCESS' | 'FAIL';
  }>('/material/get-task-progress', data);
}

// 创建视频转码的接口
export function createTranscodeProcess(data: {
  materialId?: string;
  materialType?: number; // 3-直播回放视频、4-直播录制
  fileId?: string; // 云点播fileId
}) {
  return http.post('/material/create-transcode', data);
}

// 获取存储空间接口
export function getStorage(data: { accountType: string }) {
  return http.get('/resource/get-account', data);
}

// 获取文档列表
export function getDocData(data: {
  groupId: string;
  materialType: number;
  page: number;
  pageSize: number;
}) {
  return http.get<ListMaterialGroupFileRelationReply>('/material/group/list-file-relation', data);
}

// 上传文档
export function uploadDoc(data: CreateMaterialFileReq) {
  return http.post<CommonReply>('/material/create-file', data);
}
