import { ref } from 'vue';
export const flag = ref(false);
export const passwordType = ref('password');
export const switchHandle = () => {
  if (flag.value == false) {
    flag.value = true;
    passwordType.value = 'text';
  } else {
    flag.value = false;
    passwordType.value = 'password';
  }
};
