// 伴侣
export const hostPartner = '主播伴侣';
// 数据
export const dyAccount = '抖音帐号';

// 大屏
export const bigScreenPicture = '画面大屏';
export const bigScreenData = '数据大屏';
export const bigScreenDataTwo = '数据大屏 - 数据';

// 素材
export const sourceLibrary = '素材库';
// 组织
export const organizationShop = '组织角色';
export const organizationShopCreate = '添加门店';
export const organizationShopEdit = '编辑门店';

export const organizationStaffCreate = '添加帐号';
export const organizationStaffEdit = '编辑帐号';
export const organizationStaffJoin = '审批加入';

// 直播装修
export const liveDecoration = '直播装修';
export const liveDecorationCreate = '直播装修 - 新建';
export const liveDecorationEdit = '直播装修 - 编辑';
export const liveDecorationSave = '直播装修 - 保存';

// 导播脚本
export const directorScript = '提词脚本';
export const pptScriptCreate = '素材中心 - 创建ppt脚本';
export const defineScriptCreate = '素材中心 - 创建自定义脚本';
export const pptScriptEdit = '素材中心 - 编辑ppt脚本';
export const defineScriptEdit = '素材中心 - 编辑自定义脚本';

// 直播设备
export const deviceList = '直播设备';
export const deviceDetails = '设备详情';

// 插件
export const plugins = '插件';

// 在线作图
export const onlineDesign = '在线作图';

// M34相机
export const cameraDebug = '调参';
export const cameraAbout = '关于';
export const cameraPath = ['#/camera-setting', '#/camera-about'];
