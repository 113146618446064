import { reactive } from 'vue';

export const txCaptchaReq = reactive({
  ticket: '',
  randStr: '',
});
export const resetTxCaptchaReq = (): void => {
  txCaptchaReq.randStr = '';
  txCaptchaReq.ticket = '';
};
export const captchaFn = (fn: () => void) => {
  const captcha = new (window as any).TencentCaptcha('2061050294', (res: any) => {
    if (res.ret !== 0) return;
    txCaptchaReq.randStr = res.randstr;
    txCaptchaReq.ticket = res.ticket;
    fn();
  });
  captcha.show(); // 显示验证码
};
