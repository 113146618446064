import { getOrgListMember } from '@/api/organization';
import { AllState } from '../../index';
import { IOrganizationState, IMemberInfo } from './type';
import { Module } from 'vuex';
const organization: Module<IOrganizationState, AllState> = {
  namespaced: true,
  state() {
    return {
      accountInfo: {
        dataSource: [],
        shopNumber: 0,
      },
    };
  },
  getters: {
    shopNumber(state) {
      return state.accountInfo.shopNumber;
    },
  },
  mutations: {
    changAccountInfoDataSource(state, payload: any[]) {
      state.accountInfo.dataSource = payload;
    },
    setShopNumber(state, payload: string | number) {
      console.log('payload', payload);

      state.accountInfo.shopNumber = payload;
    },
  },
  // getters: {

  //   pageListCount(state) {
  //     return (pageName: string) => {
  //       return (state as any)[`${pageName}Count`]
  //     }
  //   }
  // },
  actions: {
    async getOrgListMemberInfo({ commit }, payload: IMemberInfo) {
      const res = await getOrgListMember(payload);
      commit('changAccountInfoDataSource', res.data);
    },
  },
};

export default organization;
