import { materialList, materialUpdate, materialDel, updateInfo } from '@/api/source/library';
import { AllState } from '../../index';
import { IItem, ILibraryState, IMaterialList, ITableDataItem } from './type';
import { Module } from 'vuex';
import { formatHelper } from '@yunxi/helper';
const library: Module<ILibraryState, AllState> = {
  namespaced: true,
  state: {
    libraryInfo: {
      albumsSource: [] as Array<IItem>,
      tableDataSource: [] as Array<ITableDataItem>,
      imagesDataSource: [],
      resourceStorage: {},
      total: 0,
      videoTotal: 0,
    },
  },
  mutations: {
    changLibraryInfoAlbumsSource(state, payload: any[]) {
      payload.forEach(item => {
        item.value = {
          groupId: item.groupId,
          name: item.name,
          materialCount: item.materialCount,
          materialType: item.materialType,
        };
      });
      state.libraryInfo.albumsSource = payload;
    },
    changLibraryInfoTableDataSource(state, payload: any) {
      payload.data.forEach((item: any) => {
        item.name = item.materialVideo.name;
        item.size = formatHelper.formatSize(item.materialVideo.videoSize);
        item.url = item.materialVideo.videoUrl;
      });
      state.libraryInfo.tableDataSource = payload.data;
      state.libraryInfo.videoTotal = payload.total;
    },
    changLibraryInfoImagesDataSource(state, payload: any) {
      payload.data.forEach((item: any) => {
        item.name = item.materialImage.name;
        item.url = item.materialImage.imageUrl;
        item.value = {
          name: item.materialImage.name,
          url: item.materialImage.imageUrl,
          materialId: item.materialImage.imageId,
          materialType: item.materialType,
          imageId: item.materialImage.imageId,
        };
        item.imageId = item.materialImage.imageId;
      });
      state.libraryInfo.imagesDataSource = payload.data;
      state.libraryInfo.total = payload.total;
    },
  },
  getters: {
    albumsData(state) {
      return state.libraryInfo.albumsSource;
    },
    tableData(state) {
      return state.libraryInfo.tableDataSource;
    },
    imagesData(state) {
      return state.libraryInfo.imagesDataSource;
    },
    resourceData(state) {
      return state.libraryInfo.resourceStorage;
    },
    total(state) {
      return state.libraryInfo.total;
    },
    videoTotal(state) {
      return state.libraryInfo.videoTotal;
    },
  },

  actions: {
    /** 获取左侧分组数据 */
    getOrgListMemberInfo({ commit }, payload: IMaterialList) {
      return new Promise(async (resolve, reject) => {
        await materialList(payload)
          .then(e => {
            const res = e.data;
            commit('changLibraryInfoAlbumsSource', res);
            resolve(e);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    // 更新
    async updateLibraryGroup({}, payload: any) {
      await materialUpdate(payload);
    },
    // 删除分组
    deleteLibraryGroup({}, payload: any) {
      materialDel(payload);
    },

    // 修改素材信息接口
    async addLibraryUpdateInfo({}, payload: any) {
      await updateInfo(payload);
    },
  },
};

export default library;
