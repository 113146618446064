<template>
  <div class="call-center-pop" @click.self="popShow = false" v-if="popShow">
    <div class="call-center">
      <p>在线反馈</p>
      <p>
        感谢您的关注，您可以通过发送邮件到complaint@yunxi.tv的形式进行反馈。我们将在2个工作日内回复您，谢谢。
      </p>
      <p @click.self="popShow = false">好的</p>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const popShow = computed({
      get: () => props.visible,
      set: val => emit('update:visible', val),
    });

    return {
      popShow,
    };
  },
});
</script>
<style lang="scss" scoped>
.call-center-pop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
}

.call-center {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 99;
  width: 251px;
  background: #fff;
  border-radius: 14px;
  box-shadow: 0 1px 6px 0 rgba(0, 61, 140, 0.09);
  transform: translate(-50%, -50%);

  p {
    padding: 0 24px;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    color: #333;
    text-align: center;

    &:nth-of-type(2) {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: rgba(0, 0, 0, 0.6);
      text-align: left;
    }

    &:nth-of-type(3) {
      margin-top: 24px;
      line-height: 40px;
      color: #1374f6;
      cursor: pointer;
      border-top: 1px solid #ddd;
    }
  }
}
</style>
