import { Router } from 'vue-router';

import tokenServer from '@/service/token';
import store from '@/store';

const loginPath = '/login';
const defultPath = '/';

// 白名单(路径)
export const whiteList = [
  '/login',
  '/login/register',
  '/login/reset',
  '/login/qrcode',
  '/403',
  '/404',
  '/500',
];

// 权限验证

export const permission = (router: Router) => {
  router.beforeEach(async (to, _from, next) => {
    if (tokenServer.getToken()) {
      if (to.path === loginPath) {
        next({ path: defultPath });
      } else {
        if (!store.state.user.userId) {
          try {
            await store.dispatch('user/userInfo');
            await store.dispatch('user/orgInfo');
            await store.dispatch('script/typeList');
            await store.dispatch('flowStore/updateRemainInfo');
            if (
              !store.state.user.orgs[0]?.orgId ||
              store.state.user.roles[0]?.roleId === '404009370855149568'
            )
              location.href = import.meta.env.VITE_APP_SAAS_HOST + '?from=partner';
            else next();
          } catch (error) {
            tokenServer.removeToken();
            next({ path: loginPath, query: to.query });
          }
        } else {
          if (to.matched.length === 0) {
            next('/404'); // 判断此跳转路由的来源路由是否存在，存在的情况跳转到来源路由，否则跳转到404页面
          }
          if (
            !store.state.user.orgs[0]?.orgId ||
            store.state.user.roles[0]?.roleId === '404009370855149568'
          )
            location.href = import.meta.env.VITE_APP_SAAS_HOST + '?from=partner';
          else next();
        }
      }
    } else {
      if (whiteList.includes(to.path)) {
        next();
      } else {
        next({ path: loginPath, query: to.query });
      }
    }
  });
};
