import Home from '@/views/home/index.vue';
import { deviceDetails, deviceList } from '@/constant/route-name';

const deviceRouter = {
  path: '/device',
  component: Home,
  redirect: { name: deviceList },
  children: [
    {
      name: deviceList,
      path: 'device-list',
      component: () => import('@/views/device/device-list.vue'),
    },
    {
      name: deviceDetails,
      path: 'device-details',
      meta: {
        name: deviceDetails,
        parentRouteName: deviceList,
      },
      component: () => import('@/views/device/device-details.vue'),
    },
  ],
};

export default deviceRouter;
