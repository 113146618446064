import { getTypeList } from '@/api/source/live-script';
import { ActionContext } from 'vuex';
const state = {
  // 卡片类型
  cardTypeList: [],
};

const script = {
  namespaced: true,

  state,

  mutations: {
    setTypeList(state: { cardTypeList: any[] }, data: any[]) {
      state.cardTypeList = data;
    },
  },

  actions: {
    typeList(context: ActionContext<any, any>) {
      return new Promise((resolve, reject) => {
        getTypeList()
          .then(res => {
            const { data } = res;
            context.commit('setTypeList', data || []);
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
  },
};

export default script;
