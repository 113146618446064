<template>
  <a-modal
    :maskClosable="false"
    :destroyOnClose="true"
    class="dialog-reset-password"
    title="修改密码"
    @close="handleClose"
    v-model:visible="resetVisible"
  >
    <a-form
      ref="formRef"
      labelAlign="left"
      hideRequiredMark
      style="width: 335px"
      :model="form"
      :label-col="{ style: 'width: 82px;padding-right:12px' }"
      autocomplete="new-password"
      :rules="rules"
    >
      <a-form-item name="mobile" label="手机号码:">
        {{ form.mobile }}
      </a-form-item>
      <a-form-item label="验证码:" name="code">
        <a-input
          v-model:value="form.code"
          autocomplete="off"
          placeholder="请填写验证码"
          class="w-122 m-r-16"
          oninput="value=value.replace(/[^\d]/g,''); if(value.length>4)value=value.slice(0,4)"
        >
        </a-input>
        <a-button :disabled="count !== 60" type="primary" @click="sendSms">{{ sms }}</a-button>
      </a-form-item>
      <a-form-item label="设置密码:" name="password">
        <a-input
          v-model:value="form.password"
          autocomplete="new-password"
          type="password"
          placeholder="请输入密码"
          class="w-240"
        >
        </a-input>
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button @click="resetVisible = false">取消</a-button>
      <a-button type="primary" @click="handleSubmit">确定</a-button>
    </template>
  </a-modal>
</template>

<script lang="ts">
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
import { defineComponent, reactive, ref, computed, onMounted } from 'vue';
import { message } from 'ant-design-vue';
import type { RuleObject } from 'ant-design-vue/es/form';
import { count, countDown, sms } from '@/views/login/service/sms';
import { captchaFn, resetTxCaptchaReq, txCaptchaReq } from '@/views/login/service/txCaptcha';
import { validateCode } from '@/views/login/service/validate';
import { updatePassword, userSendSms } from '@/api/user';
import { flag, passwordType, switchHandle } from '@/views/login/service/password';
export default defineComponent({
  name: 'reset',
  computed: {
    ...mapState({
      mobile: (state: any) => state.user.mobile,
    }),
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    /** 是否注册 */
    enum smsType {
      /** 否 */
      login,
      /** 是 */
      register,
      /** 修改密码 */
      reset,
    }
    const resetVisible = computed({
      get: () => props.visible,
      set: val => emit('update:visible', val),
    });

    const validatePassWord = async (_rule: RuleObject, value: string) => {
      if (!/^\w{6,16}$/.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(!value ? '请输入密码' : '密码应为6-16位数字和字母');
      }
      return Promise.resolve();
    };
    const store = useStore();
    // 表单数据
    const form = reactive({
      mobile: '',
      code: null,
      password: '',
    });

    interface RuleState {
      code: Array<object>;
      password: Array<object>;
    }
    const rules = reactive<RuleState>({
      code: [
        {
          required: true,
          validator: validateCode,
          trigger: 'blur',
        },
      ],
      password: [
        {
          required: true,
          validator: validatePassWord,
          trigger: 'blur',
        },
      ],
    });

    const formRef = ref();

    const router = useRouter();

    // 自定义添加错误回调，校验后删除规则
    const errorCallback = (prop: keyof RuleState, msg: string): void => {
      rules[prop].push({
        validator: async () => {
          return Promise.reject(msg);
        },
      });
      formRef.value.validateFields(prop);
      rules[prop].pop();
      resetTxCaptchaReq();
    };
    // 获取验证码
    const getSms = async () => {
      const res = await userSendSms({
        txCaptchaReq,
        mobile: store.state.user.mobile,
        type: smsType.reset,
      });
      switch (res.data.code) {
        case 200:
          message.success('短信验证码已发送');
          countDown();
          break;
        default:
          message.error(res.data.msg);
          break;
      }
    };
    const sendSms = () => {
      formRef.value.validateFields('mobile').then(() => {
        if (count.value !== 60) return;
        captchaFn(getSms);
      });
    };
    const handleClose = () => {
      form.code = null;
      form.password = '';
    };
    const handleUpdatePassword = async () => {
      const res = await updatePassword({
        txCaptchaReq,
        ...form,
      });
      if (res.data.code === 200) {
        message.success('修改成功');
        store.commit('user/clearState');
        setTimeout(() => {
          router.push({ path: '/login' });
        }, 1000);
        return;
      }
      if (res.data.code === 1001) {
        errorCallback('code', '短信发送频繁，请稍后再试!');
        return;
      }
      if (res.data.code === 1002) {
        errorCallback('code', '验证码错误');
        return;
      }
      message.error(res.data.msg);
    };
    const handleSubmit = (): void => {
      formRef.value.validate().then(async () => {
        if (!form.code) {
          errorCallback('code', '验证码不能为空');
          return;
        }
        handleUpdatePassword();
      });
    };
    onMounted(() => {
      form.mobile = store.state.user.mobile;
    });
    return {
      formRef,
      form,
      rules,
      handleSubmit,
      sms,
      sendSms,
      count,
      flag,
      switchHandle,
      passwordType,
      resetVisible,
      handleClose,
    };
  },
});
</script>
<style lang="scss">
.dialog-reset-password {
  .ant-modal-body {
    padding-bottom: 12px;
  }
}
</style>
