import { http, rawHttp } from '../http-server';
import {
  createOrgType,
  updateOrgType,
  IListStore,
  createChildType,
  createFaceVerifyType,
  updateChildType,
  updateOrgStatus,
  IListMember,
} from './interface';
import { IPagination } from '../interface';

/** 创建组织 */
export function createOrg(payload: createOrgType) {
  return http.post('/org/create', payload);
}

/** 修改组织 */
export function updateOrg(payload: updateOrgType) {
  return http.post('/org/update', payload);
}

/** 获取组织信息 */
export function getOrg(payload: { orgId?: string; name?: string }) {
  return http.get('/org/get', payload);
}

/** 获取门店列表 */
export function getListStore(payload: IListStore) {
  return http.get('/org/list-store', payload);
}

// 角色列表
export function getListRoles() {
  return http.get('/permission/list-roles-by-biz');
}

// 添加帐号
export function createChild(payload: createChildType) {
  return rawHttp.post('/user/create-child', payload);
}

// 添加主播认证
export function createFaceVerify(payload: createFaceVerifyType) {
  return http.post('/user/create-face-verify', payload);
}

// 编辑帐号
export function updateChild(payload: updateChildType) {
  return http.post('/user/update-child', payload);
}
// 查询主播认证状态
export function getFaceVerifyStatus(payload: { certifyId?: string; userId?: string }) {
  return http.get('/user/get-face-verify-status', payload);
}

/** 启用/禁用门店 */
export function updateOrgStatus(payload: updateOrgStatus) {
  return http.post('/org/update-status', payload);
}

/** 组织架构 */
export function getOrgList() {
  return http.post('/org/list');
}

/** 获取权限树 */
export function getListTreePermission() {
  return http.get('/permission/list-tree-permission');
}

/** 获取角色列表 */
export function getListRole() {
  return http.get('/permission/list-roles-by-biz');
}
/** 获取角色对应的权限 */
export function getPersonRole(payload: unknown) {
  return http.get('/permission/list-permission-node', payload);
}

/** 查询组织成员信息 */
export function getOrgListMember(payload: IListMember) {
  return http.get('/org/list-member', payload);
}

/** 启用禁用子帐号 */
export function updateMemberStatus(payload: { orgId?: string; userId: string; status: number }) {
  return http.post('/org/update-member-status', payload);
}

/** 获取总组织下门店数量 */
export function getOrgStoreNum() {
  return http.get('/org/store-num');
}

/** 获取总组织下帐号数量 */
export function getOrgMemberNum() {
  return http.get('/org/member-num');
}

/** 账户申请列表 */
export function getAccountApplyList(payload: IPagination) {
  return http.get('/org/account-apply-list', payload);
}

/** 同意帐号申请 */
export function agreeAccountApply(payload: updateChildType) {
  return http.post('/org/agree-account-apply', payload);
}

/** 拒绝帐号申请 */
export function disagreeAccountApply(payload: { userId: string }) {
  return http.post('/org/disagree-account-apply', payload);
}
