import { createStore } from 'vuex';

import flowStore from './modules/flowStore';
import library from './modules/library/library';
import organization from './modules/organization/organization';
import script from './modules/script';
import user, { UserState } from './modules/user';
export interface AllState {
  user: UserState;
}

// 实例化
const store = createStore<AllState>({
  modules: {
    user,
    organization,
    library,
    script,
    flowStore,
  },
});

export default store;
