import {
  cameraAbout,
  cameraDebug,
  cameraPath,
  directorScript,
  // liveDecoration,
  // onlineDesign,
  sourceLibrary,
} from '@/constant/route-name';

// import { toKj } from '@/utils';

let menuData = [
  /*{
    path: '/data',
    label: '数据',
    fullLabel: '分犀师',
    children: [
      {
        label: dyAccount,
      },
      {
        label: bigScreenDataTwo,
        showName: '数据大屏',
      },
    ],
    icon: 'icon-fxs',
    activeIcon: 'icon-fxs_xz',
  },*/
  {
    path: '/partner',
    label: '伴侣',
    fullLabel: '主播伴侣',
    children: [],
    icon: 'icon-bl',
    activeIcon: 'icon-bl_xz',
  },
  {
    path: '/source',
    label: '素材',
    fullLabel: '素材中心',
    children: [
      /*{
        label: liveDecoration,
      },*/
      {
        label: directorScript,
      },
      // {
      //   label: onlineDesign,
      // },
      // {
      //   label: '云犀快剪',
      //   fn: () => {
      //     toKj();
      //   },
      // },
      {
        label: sourceLibrary,
      },
    ],
    icon: 'icon-sc',
    activeIcon: 'icon-sc_xz',
  },
  {
    path: '/big-screen',
    label: '大屏',
    fullLabel: '大屏看板',
    children: [
      /*{
        label: bigScreenPicture,
      },*/
      /*{
        label: bigScreenData,
      },*/
    ],
    icon: 'icon-big-screen',
    activeIcon: 'icon-big-screen_xz',
  },
  {
    path: '/device',
    label: '设备',
    fullLabel: '设备',
    children: [],
    icon: 'icon-sb',
    activeIcon: 'icon-sb_xz',
  },
  {
    path: '/plugins',
    label: '插件',
    fullLabel: '插件',
    children: [],
    icon: 'icon-sb',
    activeIcon: 'icon-sb_xz',
  },
];

const setCameraMet = () => {
  menuData = [
    {
      path: '/camera-setting',
      label: cameraDebug,
      fullLabel: cameraDebug,
      children: [],
      icon: 'icon-debug',
      activeIcon: 'icon-debug_xz',
    },
    {
      path: '/camera-about',
      label: cameraAbout,
      fullLabel: cameraAbout,
      children: [],
      icon: 'icon-about',
      activeIcon: 'icon-about_xz',
    },
  ];
};
const idx = location.hash.indexOf('?');
if (idx === -1 && cameraPath.includes(location.hash)) {
  setCameraMet();
} else if (cameraPath.includes(location.hash.slice(0, idx))) {
  setCameraMet();
}

export default menuData;
