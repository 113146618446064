import { TokenServer } from '@yunxi/service';
const TokenKeyMap = {
  test: 'y-token-test',
  pre: 'y-token-pre',
  production: 'y-token',
  development: 'y-token',
};
const TokenKey = TokenKeyMap[import.meta.env.VITE_ENV] || 'y-token';
const expires = 30;
// const domain = import.meta.env.VITE_ENV === 'development' ? '192.168.31.183' : '.yunxi.tv';
const domain = import.meta.env.VITE_ENV === 'development' ? 'localhost' : '.yunxi.tv';

export default TokenServer.getInstance(TokenKey, { expires, domain });
