import { ref } from 'vue';
import { PollingService } from '@yunxi/helper';

export const sms = ref('获取验证码');
export const count = ref<number>(60);
export const countDown = async () => {
  await PollingService.start(() => {
    count.value--;
    if (count.value <= 0) {
      PollingService.stop();
      count.value = 60;
      sms.value = '获取验证码';
      return;
    }
    sms.value = `重新获取${count.value}s`;
  }, 1000);
};
