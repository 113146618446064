import { formatHelper } from '@yunxi/helper';
import { ActionContext } from 'vuex';

import { getAllResourceRemain } from '@/api/source';
const state = {
  resourceStorage: {},
  resourceStream: {},
  resourceDyAccounts: {},
};
const types = {
  UPDATE_INFO: 'UPDATE_INFO',
};
const flowStore = {
  namespaced: true,

  state,

  mutations: {
    [types.UPDATE_INFO]: (
      state: {
        resourceStorage: {
          remainAmount: string;
          totalAmount: string;
          useAmount: string;
          remainPercentage: number;
        };
        resourceStream: {
          remainAmount: string;
          totalAmount: string;
          useAmount: string;
          remainPercentage: number;
        };
      },
      payload: { resourceStorage: any; resourceStream: any },
    ) => {
      const store = payload.resourceStorage;
      const flow = payload.resourceStream;
      if (store) {
        const storePercent = store.remainAmount / store.totalAmount;
        state.resourceStorage = {
          remainAmount: formatHelper.formatSize(store.remainAmount),
          totalAmount: formatHelper.formatSize(store.totalAmount),
          useAmount: formatHelper.formatSize(store.useAmount),
          remainPercentage: storePercent || 0,
        };
      }
      if (flow) {
        const flowPercent = flow.remainAmount / flow.totalAmount;
        state.resourceStream = {
          remainAmount: formatHelper.formatSize(flow.remainAmount),
          totalAmount: formatHelper.formatSize(flow.totalAmount),
          useAmount: formatHelper.formatSize(flow.useAmount),
          remainPercentage: flowPercent || 0,
        };
      }
    },
  },

  actions: {
    async updateRemainInfo(context: ActionContext<any, any>) {
      const { data } = await getAllResourceRemain();
      context.commit(types.UPDATE_INFO, data);
    },
  },
};

export default flowStore;
