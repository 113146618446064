<template>
  <div :class="['menu', currentMenu.children.length ? '' : 'menu-radius']">
    <div class="menu__sup">
      <div class="menu__logo">
        <div class="menu__logo-icon">
          <img src="@/assets/images/common/chunbai.svg" />
        </div>
      </div>

      <div
        :class="{
          menu__item: true,
          'menu__item-active': route.matched[0].path === item.path,
        }"
        :key="item.path"
        v-for="item in menuData"
        @click="handleClick(item)"
      >
        <span
          :class="[
            'iconfont',
            route.matched[0].path === item.path ? 'ui-' + item.activeIcon : 'ui-' + item.icon,
          ]"
        ></span>
        {{ item.label }}
      </div>
      <template v-if="showSettings">
        <div class="menu__item" @click="organize">
          <span class="iconfont ui-icon-zz"></span> 组织
        </div>
        <div class="menu__item menu__setting" @click="setting">
          <span class="iconfont ui-icon-setting"></span> 设置
        </div>
      </template>
    </div>
    <div class="menu__sub" v-if="currentMenu.children.length">
      <div class="menu__sub-title">{{ currentMenu.fullLabel }}</div>
      <ul class="menu__sub-box">
        <template v-for="item in currentMenu.children" :key="item.label">
          <template
            v-if="item.fn && Object.prototype.toString.call(item.fn).slice(8, -1) === 'Function'"
          >
            <li @click="linkFn(item.fn)">
              {{ item.label }}
            </li>
          </template>
          <router-link v-else :to="{ name: item.label }">
            <li
              :class="{
                'active-menu':
                  route.matched[1].name === item.label || route.meta.parentRouteName === item.label,
              }"
            >
              {{ item.showName || item.label }}
              <span v-if="item.label === '在线作图'" class="beta-icon"></span>
            </li>
          </router-link>
        </template>
      </ul>
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, watch } from 'vue';
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';

import menuData from '@/config/menu-config';
import { cameraPath } from '@/constant/route-name';
export default defineComponent({
  name: 'layoutMenu',
  setup(_props, { emit }) {
    const route = useRoute();
    const router = useRouter();

    const showSettings = computed(() => {
      const idx = location.hash.indexOf('?');
      if (idx === -1) return !cameraPath.includes(location.hash);
      return !cameraPath.includes(location.hash.slice(0, idx));
    });

    //console.log(route.matched,111111)
    const currentMenu = computed(() => {
      return menuData.find(menu => route.matched[0].path === menu.path) || ([] as any);
    });
    //console.log(currentMenu, 1212312)

    const handleClick = (item: { path: RouteLocationRaw }) => {
      router.push(item.path);
    };
    const organize = () => {
      window.open(
        `${
          import.meta.env.VITE_APP_SAAS_HOST
        }/#/application/business-setting/setting-organization/setting-store?type=partner`,
        '__blank',
      );
    };
    const linkFn = fn => {
      fn();
    };
    const setting = () => {
      window.open(
        `${import.meta.env.VITE_APP_SAAS_HOST}/#/application/business-setting?type=partner`,
        '__blank',
      );
    };
    watch(
      currentMenu,
      menuData => {
        emit('update:width', menuData?.children?.length ? 208 : 108);
      },
      { immediate: true },
    );

    return { showSettings, menuData, setting, route, handleClick, currentMenu, organize, linkFn };
  },
});
</script>
<style></style>
<style lang="scss" scoped>
.menu-radius {
  &::before {
    position: absolute;
    top: 0;
    right: -14px;
    z-index: 999;
    display: block;
    width: 14px;
    height: 14px;
    content: '';
    background: radial-gradient(14px at 0 0, transparent 14px, #1374f6 14px);
    transform: rotate(180deg);
  }

  &::after {
    position: absolute;
    right: -14px;
    bottom: 0;
    z-index: 999;
    display: block;
    width: 14px;
    height: 14px;
    content: '';
    background: radial-gradient(14px at 0 0, transparent 14px, #1374f6 14px);
    transform: rotate(90deg);
  }
}

.menu {
  position: fixed;
  z-index: 999;
  display: flex;

  .iconfont {
    margin-right: 14px;
    margin-left: 12px;
  }

  .menu__sup {
    width: 108px;
    height: 100vh;
    padding: 24px 0 0 14px;
    background: #1374f6;

    .menu__logo {
      margin-bottom: 24px;

      & .menu__logo-icon {
        width: 80px;
        height: 46px;
        font-family: STYuanti-SC-Bold, STYuanti-SC, serif;
        font-size: 18px;
        font-weight: bold;
        line-height: 46px;
        color: #fff;
        white-space: nowrap;

        img {
          width: 80px;
        }

        .menu__logo-text {
          width: 96px;
          margin-top: 8px;
          font-size: 10px;
          line-height: 12px;
          color: #f7b52c;
          transform: scale(0.833333);
          transform-origin: left center;
        }
      }
    }

    .menu__item {
      display: flex;
      align-items: center;
      height: 36px;
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      cursor: pointer;
      background: #1374f6;
    }

    .menu__setting {
      position: absolute;
      bottom: 44px;
    }

    .menu__item-active {
      position: relative;
      color: #1374f6;
      background: #fff;
      border-radius: 8px 0 0 8px;
    }

    .menu__item-active::before {
      position: absolute;
      top: -8px;
      right: 0;
      display: block;
      width: 8px;
      height: 8px;
      content: '';
      background: radial-gradient(8px at 0 0, transparent 8px, #fff 8px);
    }

    .menu__item-active::after {
      position: absolute;
      right: 0;
      bottom: -8px;
      display: block;
      width: 8px;
      height: 8px;
      content: '';
      background: radial-gradient(8px at 0 8px, transparent 8px, #fff 8px);
    }
  }

  .menu__sub {
    width: 100px;
    padding: 0 14px;
    background: #fff;
    border-right: 1px solid rgba(0, 0, 0, 0.04);
    border-top-left-radius: 14px;
    border-bottom-left-radius: 14px;

    .menu__sub-title {
      font-size: 14px;
      font-weight: 600;
      line-height: 64px;
      color: rgba(0, 0, 0, 0.8);
      text-align: center;
    }

    .menu__sub-box {
      li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 72px;
        height: 34px;
        margin-bottom: 4px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
        cursor: pointer;
      }

      .beta-icon {
        position: absolute;
        top: -6px;
        right: -10px;
        display: inline-block;
        width: 27px;
        height: 16px;
        background: url('https://static.yunxi.tv/yunxi/business/vue/2.2.0/static/img/images/6e8c3d588bd9ebf6f3b9.svg')
          no-repeat;
        background-color: #ff4949;
        border-radius: 5px 5px 5px 0;
        box-shadow: 0 3px 6px -2px rgba(255, 73, 73, 0.4);
      }

      .active-menu {
        color: #1374f6;
        background: #f0f6ff;
        border-radius: 8px;
      }
    }
  }
}
</style>
