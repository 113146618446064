import { bigScreenPicture } from '@/constant/route-name';
import Home from '@/views/home/index.vue';

const router = {
  path: '/big-screen',
  component: Home,
  redirect: { name: bigScreenPicture },
  children: [
    {
      name: bigScreenPicture,
      path: 'picture',
      component: () => import('@/views/big-screen/picture/index.vue'),
    },
    /*{
      name: bigScreenData,
      path: 'data',
      component: () => import('@/views/big-screen/data/index.vue'),
    },*/
  ],
};

export default router;
