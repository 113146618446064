<template>
  <a-modal
    :maskClosable="false"
    :destroyOnClose="true"
    class="dialog-reset-password"
    title="修改密码"
    @close="handleClose"
    v-model:visible="resetVisible"
  >
    <a-form
      ref="formRef"
      labelAlign="left"
      hideRequiredMark
      style="width: 335px"
      :model="form"
      :label-col="{ style: 'width: 82px;padding-right:12px' }"
      autocomplete="new-password"
      :rules="rules"
    >
      <a-form-item name="mobile" label="帐号:">
        {{ accountId }}
      </a-form-item>
      <a-form-item label="旧密码:" name="oldPassword">
        <a-input
          v-model:value="form.oldPassword"
          autocomplete="new-password"
          type="password"
          placeholder="请输入旧密码"
          class="w-240"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="新密码:" name="newPassword">
        <a-input
          v-model:value="form.newPassword"
          autocomplete="new-password"
          type="password"
          placeholder="请输入新密码"
          class="w-240"
        >
        </a-input>
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button @click="resetVisible = false">取消</a-button>
      <a-button type="primary" @click="handleSubmit">确定</a-button>
    </template>
  </a-modal>
</template>

<script lang="ts">
import { useStore, mapState } from 'vuex';
import { useRouter } from 'vue-router';
import { defineComponent, reactive, ref, computed } from 'vue';
import { message } from 'ant-design-vue';
import type { RuleObject } from 'ant-design-vue/es/form';
import { changePassword } from '@/api/user';
import { flag, passwordType, switchHandle } from '@/views/login/service/password';
export default defineComponent({
  name: 'reset',
  computed: {
    ...mapState({
      accountId: (state: any) => state.user.accountId,
    }),
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const resetVisible = computed({
      get: () => props.visible,
      set: val => emit('update:visible', val),
    });

    const validatePassWord = async (_rule: RuleObject, value: string) => {
      if (!/^\w{6,16}$/.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(!value ? '请输入密码' : '密码应为6-16位数字和字母');
      }
      return Promise.resolve();
    };
    const store = useStore();
    // 表单数据
    const form = reactive({
      oldPassword: '',
      newPassword: '',
    });

    interface RuleState {
      oldPassword: Array<object>;
      newPassword: Array<object>;
    }
    const rules = reactive<RuleState>({
      oldPassword: [
        {
          required: true,
          validator: validatePassWord,
          trigger: 'blur',
        },
      ],
      newPassword: [
        {
          required: true,
          validator: validatePassWord,
          trigger: 'blur',
        },
      ],
    });

    const formRef = ref();

    const router = useRouter();

    const handleClose = () => {
      form.newPassword = '';
      form.oldPassword = '';
    };
    const handleChangePassword = async () => {
      const res = await changePassword({
        ...form,
      });
      if (res.data.code === 200) {
        message.success('修改成功');
        store.commit('user/clearState');
        setTimeout(() => {
          router.push({ path: '/login' });
        }, 1000);
        return;
      }
      message.error(res.data.msg);
    };
    const handleSubmit = (): void => {
      formRef.value.validate().then(async () => {
        handleChangePassword();
      });
    };
    return {
      formRef,
      form,
      rules,
      handleSubmit,
      flag,
      switchHandle,
      passwordType,
      resetVisible,
      handleClose,
    };
  },
});
</script>
<style lang="scss">
.dialog-reset-password {
  .ant-modal-body {
    padding-bottom: 12px;
  }
}
</style>
