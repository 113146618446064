import { hostPartner } from '@/constant/route-name';
import Home from '@/views/home/index.vue';

const partnerRouter = {
  path: '/partner',
  component: Home,
  redirect: { name: hostPartner },
  children: [
    {
      name: hostPartner,
      path: 'host-partner',
      component: () => import('@/views/partner/host-partner/index.vue'),
    },
  ],
};

export default partnerRouter;
