export enum MessageType {
  /** 系统消息 */
  system = 1,
  /** 申请加入 */
  apply,
  /** 资源存储 */
  resource,
  /** 流量 */
  stream,
  /** 版本 */
  version,
  /** 内容违规 */
  irregularity,
}

/** 消息类型map */
export const messageTypeMap = {
  1: '系统消息',
  2: '加入申请',
  3: '资源不足提醒',
  4: '资源不足提醒',
  5: '版本到期提醒',
  6: '内容违规提醒',
};
