import { ActionContext } from 'vuex';

import { getOrg } from '@/api/organization';
import { getInfo, login } from '@/api/user';
import { LoginType } from '@/api/user/interface';
import { Role } from '@/constant/org';
import tokenServer from '@/service/token';
import { projectVersionStatus } from '~shared/business-types';

import { AllState } from '../index';
const defaultAvatar =
  'https://image.yunxi.tv/recordVideo/91325/zKPcwbhjWSA8s6FBKbaDxpJeAW8hmPEX.png';
const defaultLogo = 'https://image.yunxi.tv/recordVideo/91325/hmxWdpSRJJZk7jNEmkXY2QJJrpyQydPy.png';
// 处理用户登录、个人信息

interface IRole {
  name: string;
  roleId: string;
}

interface IOrgList {
  name: string;
  rootOrgId: string;
}

interface IOrgInfo {
  orgId: string;
  projectVersion: projectVersionStatus;
}

export type UserState = {
  token: string | undefined;
  nickname: string;
  mobile: string;
  avatar: string;
  roles: IRole[];
  orgId: string;
  orgs: any;
  userId: string;
  orgInfo: IOrgInfo;
  accountId: string;
  rootOrgList: IOrgList[];
  kjIsActive: number;
};

const state: UserState = {
  // 标识
  token: tokenServer.getToken(),
  // 昵称
  nickname: '',
  // 头像
  avatar: '',
  // 角色(鉴权)
  roles: [],
  // 手机号
  mobile: '',
  //组织id
  orgId: '',
  //组织
  orgs: [],
  userId: '',
  orgInfo: {
    orgId: '',
    projectVersion: projectVersionStatus.Start,
  },
  accountId: '',
  rootOrgList: [],
  kjIsActive: 0,
};

const user = {
  namespaced: true,

  state,

  mutations: {
    // 设置token
    setToken(state: UserState, token: string) {
      state.token = token;
    },

    // 设置用户信息
    setInfo(state: UserState, info: any) {
      const { nickname, avatar, roles, mobile, orgs, userId, rootOrgId, accountId, rootOrgList } =
        info;
      state.nickname = nickname;
      state.avatar =
        avatar ||
        (roles.map((role: { roleId: string }) => role.roleId).includes(Role.superAdmin)
          ? defaultLogo
          : defaultAvatar);
      state.roles = roles;
      state.mobile = mobile;
      state.orgId = rootOrgId;
      state.orgs = orgs;
      state.userId = userId;
      state.accountId = accountId;
      state.rootOrgList = rootOrgList;
    },
    setOrgInfo: (state: UserState, orgInfo: any) => {
      state.orgInfo = orgInfo;
      const funcList = orgInfo?.funcList ?? [];
      if (funcList.length) {
        const kj =
          funcList.find((item: { projectVersion: number }) => item.projectVersion === 6) || {};
        state.kjIsActive = kj?.isActive ?? 0;
      }
    },
    // 用户退出登录
    clearState(state: UserState) {
      tokenServer.removeToken();
      // 为了重新加载用户信息
      state.userId = '';
      state.mobile = '';
      state.avatar = '';
      state.nickname = '';
    },
  },

  actions: {
    logout(context: ActionContext<UserState, AllState>) {
      return new Promise<void>(resolve => {
        context.commit('clearState');
        resolve();
      });
    },
    // 登录
    login(context: ActionContext<UserState, AllState>, params: LoginType) {
      return new Promise((resolve, reject) => {
        login(params)
          .then(e => {
            const data = e.data?.data;
            if (data) {
              tokenServer.setToken(data.accessToken);
              context.commit('setToken', data.accessToken);
            }
            resolve(e);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    // 获取用户信息
    userInfo(context: ActionContext<UserState, AllState>) {
      return new Promise((resolve, reject) => {
        getInfo({})
          .then(e => {
            const info = e.data;
            context.commit('setInfo', info);
            resolve(e);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    // 获取用户信息
    orgInfo(context: ActionContext<UserState, AllState>) {
      return new Promise((resolve, reject) => {
        getOrg({})
          .then(e => {
            const info = e.data;
            context.commit('setOrgInfo', info);
            resolve(e);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
  },
};

export default user;
