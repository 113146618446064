<template>
  <div class="cpn-resource-use">
    <p class="cpn-resource-use__para">
      <span class="cpn-resource-use__show-type">{{ showType }}剩余：</span>
      <span class="cpn-resource-use__show-remain-amount">{{ showRemainAmount }}</span>
    </p>
    <a-progress :strokeColor="showStatus" :percent="percentage" :show-info="false" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
const props = defineProps({
  showType: {
    type: String,
    default: '流量',
  },
});
const store = useStore();
const resourceStorage = computed(() => store.state.flowStore.resourceStorage);
const resourceStream = computed(() => store.state.flowStore.resourceStream);

const showRemainAmount = computed(() => {
  if (props.showType === '流量') {
    return resourceStream.value.remainAmount;
  }
  return resourceStorage.value.remainAmount;
});

const percentage = computed(() => {
  if (props.showType === '流量') {
    return resourceStream.value.remainPercentage <= 0
      ? 100
      : Math.ceil((1 - resourceStream.value.remainPercentage) * 100);
  }
  return resourceStorage.value.remainPercentage <= 0
    ? 100
    : Math.ceil((1 - resourceStorage.value.remainPercentage) * 100);
});

const showStatus = computed(() => {
  if (percentage.value > 90) return '#f62213';
  if (percentage.value > 70) return '#f39000';
  if (percentage.value <= 70) return '#1374f6;';
  return '';
});
</script>

<style lang="scss" scoped>
.cpn-resource-use {
  display: flex;
  flex-direction: column;
  align-self: baseline;
  padding-top: 18px;

  :deep(.ant-progress-outer) {
    display: flex;
  }

  &__para {
    padding-bottom: 8px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
  }

  &__show-type {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.8);
  }

  &__show-remain-amount {
    margin-left: 34px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
  }
}
</style>
