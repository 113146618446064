import { plugins } from '@/constant/route-name';
import Home from '@/views/home/index.vue';

const router = {
  path: '/plugins',
  component: Home,
  redirect: { name: plugins },
  children: [
    {
      name: plugins,
      path: '',
      component: () => import('@/views/plugins/index.vue'),
    },
  ],
};

export default router;
